import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import './404.scss'

const NotFoundPage = location => (
  <Layout>
    <Meta
      data={{
        pathName: location.pathname,
        title: 'Page not found',
        description: 'The page you are looking for does not exist.',
      }}
    />
    <div className="container container-compact error">
      <h1>Page not found</h1>
      <h2>
        The page you are looking for does not exist. Maybe you're interested in
        our <Link to="/">work</Link>?
      </h2>
    </div>
  </Layout>
)

export default NotFoundPage
